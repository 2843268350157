<template>
  <v-container fluid>
    <v-form ref="userForm" v-model="formIsValid" lazy-validation>
      <v-card class="mx-auto my-3" style="max-width: 600px">
        <v-card-title class="justify-center" style="background: #f5f5f5">
          <h4 class="text-h4 font-weight-regular">
            {{ type === "edit" ? "Update" : "Create" }} User
          </h4>
        </v-card-title>
        <v-divider />

        <v-card-text class="pt-4 px-10">
          <v-switch v-model="locked" label="Lock this account"></v-switch>

          <v-select v-model="role" :items="userRoles" outlined label="User Role" required />

          <v-text-field
            v-model="username"
            :rules="usernameRules"
            label="Username"
            :disabled="type === 'edit'"
            outlined
            required
          />

          <v-text-field
            v-model="password"
            :rules="passwordRules"
            label="Password"
            outlined
            v-if="type === 'create'"
          />
          <div class="d-flex flex-row align-center mb-7" v-else>
            <v-text-field value="****" label="Password" disabled hide-details />
            <v-btn outlined dark color="blue" @click="passwordModal = !passwordModal">
              <v-icon>mdi-refresh</v-icon> Change
            </v-btn>
          </div>

          <v-text-field v-model="fullName" outlined label="Full Name" />
          <v-text-field
            v-model="email"
            type="email"
            outlined
            :rules="emailRules"
            label="Email Address"
          />
        </v-card-text>

        <v-divider />
        <v-card-actions class="px-5 py-4" style="background: #f5f5f5">
          <v-btn
            color="secondary"
            @click="$emit('dismiss')"
            outlined
            class="mr-4"
            style="width: 150px"
            :loading="loading"
          >
            <v-icon>mdi-chevron-left</v-icon> Back
          </v-btn>
          <v-spacer />
          <v-btn color="success" @click.stop="saveUser" type="button" style="width: 150px">
            {{ type === "edit" ? "Update" : "Create" }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>

    <v-dialog v-model="passwordModal" max-width="600px" @close="passwordModal = false">
      <!--            <template v-slot:activator="{ on, attrs }">-->
      <!--              <v-btn color="primary" dark v-bind="attrs" v-on="on"> Open Dialog </v-btn>-->
      <!--            </template>-->
      <v-card>
        <v-toolbar>
          <v-toolbar-title>Change Password</v-toolbar-title>
        </v-toolbar>
        <v-form ref="passwordForm" v-model="passwordIsValid" class="px-5 pt-5 pb-2">
          <v-card-text>
            <v-text-field v-model="username" autocomplete="username" hidden class="d-none" />
            <v-text-field
              outlined
              label="New Password"
              v-model="password"
              type="password"
              :rules="passwordRules"
              autocomplete="new-password"
              required
            ></v-text-field>
            <v-text-field
              outlined
              label="Confirm Password"
              type="password"
              v-model="passwordConfirm"
              :rules="passwordConfirmRules"
              autocomplete="new-password"
              required
            ></v-text-field>
          </v-card-text>
          <v-divider />
          <v-card-actions class="py-4">
            <v-spacer />
            <v-btn
              :loading="loading"
              class="px-10"
              dark
              color="primary"
              @click.stop="updatePasswordOnly"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { USER_CREATE, USER_UPDATE } from "@/constants/api";
import { USER_ROLES, ROLE_USER } from "@/constants/roles";

export default {
  name: "UserForm",
  props: {
    user: {
      type: Object,
      required: false,
      default: () => {
        return {
          level: 0,
          name: null,
        };
      },
    },
    type: {
      type: String,
      required: false,
      default: () => "edit",
    },
  },
  data: () => ({
    loading: false,

    email: null,
    fullName: null,
    username: null,
    locked: null,
    disabled: null,
    role: null,
    formIsValid: false,

    password: null,
    passwordConfirm: null,
    passwordIsValid: false,
    passwordModal: false,
  }),
  computed: {
    authUserRole() {
      return this.$auth.user?.role;
    },
    userRoles() {
      const myRoles = [];
      for (const role of USER_ROLES) {
        myRoles.push(role);
        if (role == this.authUserRole) break;
      }
      return myRoles;
    },
    emailRules: () => [
      (v) => {
        if (typeof v === "string" && v.length > 1) {
          return /.+@.+\..+/.test(v) ? true : "E-mail must be valid";
        }
        return true;
      },
    ],
    usernameRules() {
      return [(v) => !!v || "Username is required"];
    },
    passwordRules() {
      return [
        (v) =>
          (!v && this.type === "create") ||
          (v && v.length >= 4 && v.length < 21) ||
          "Password length should be 4 or more",
      ];
    },
    passwordConfirmRules() {
      return [() => this.password === this.passwordConfirm || "Password do not match"];
    },
  },
  watch: {
    user() {
      this.loadUser();
    },
  },
  mounted() {
    this.loadUser();
  },
  methods: {
    loadUser() {
      this.role = this.user.role || ROLE_USER;
      this.fullName = this.user.fullName;
      this.username = this.user.username;
      this.email = this.user.email;
      this.locked = this.user.locked;
      this.disabled = this.user.disabled;
      this.level = this.user.level;
      this.$refs.userForm.resetValidation();
    },
    async updatePasswordOnly() {
      await this.$refs.passwordForm.validate();
      if (!this.passwordIsValid) {
        this.$iziToast.error({
          title: "Passwords do not match",
        });
        return;
      }
      const data = {
        password: this.password,
      };
      if (await this.updateUser(data)) {
        this.passwordModal = false;
      }
    },
    async saveUser() {
      await this.$refs.userForm.validate();
      if (!this.formIsValid) {
        return this.$iziToast.error({
          title: "Some fields are not valid",
        });
      }
      this.loading = true;
      const data = {
        fullName: this.fullName,
        email: this.email,
        level: this.level,
        locked: this.locked,
        role: this.role,
      };
      if (this.type === "create") {
        data.password = this.password;
        data.username = this.username;
      } else {
        Object.keys(data).forEach((el) => {
          if (data[el] === this.user[el]) {
            delete data[el];
          }
        });
      }
      if (await this.updateUser(data)) {
        this.$emit("updated");
      }
    },
    async updateUser(data) {
      this.loading = true;
      try {
        const response = await this.$axios.post(
          this.type === "edit" ? `${USER_UPDATE}?userId=${this.user.id}` : USER_CREATE,
          data
        );
        if (response.data.code !== 0) {
          throw new Error(response.data.message);
        }
        return true;
      } catch (err) {
        this.$iziToast.showError(err);
      } finally {
        this.loading = false;
      }
      return false;
    },
  },
};
</script>

<style scoped></style>
